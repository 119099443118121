<template>
  <span class="d-inline-flex flex-column align-items-center">
    <label>{{ filter.name }}</label>
    <b-dropdown
      right
      size="sm"
      :text="getButtonText()"
      variant="outline-primary"
    >
      <b-dropdown-item-button
        v-for="(option, optionIndex) in options"
        :key="optionIndex"
        @click="onClick(option.value)"
      >
        <span class="d-inline-flex align-items-center">
          <b-icon
            v-if="isOptionActive(option.value)"
            icon="check"
            font-scale="1.5"
            class="mr-1"
            variant="success"
          ></b-icon>
          {{ option.label }}
        </span>
      </b-dropdown-item-button>
    </b-dropdown>
  </span>
</template>

<script>
export default {
  name: "ListFixedFilterSpecial",
  props: ["filter"],
  computed: {
    options() {
      return this.filter?.options || [];
    },
    selectedValue() {
      return this.filter?.selectedValue || null;
    }
  },
  methods: {
    getButtonText() {
      const optionItem = this.options.find(
        option => option.value == this.selectedValue
      );

      return optionItem?.label || "";
    },
    isOptionActive(value) {
      return this.selectedValue === value;
    },
    onClick(value) {
      this.$emit("on-fixed-filter-selected", value);
    }
  }
};
</script>
